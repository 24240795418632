<template>
  <!-- <a-spin :spinning="spinning" tip="Loading..."> -->
    <div id="LightDetails">
      <div class="header">
        <!-- 左上角头部开关 -->
        <div class="header-header">
          <span>{{ deviceName }}</span>
          <div class="switch" @click.stop>
            <a-switch
              v-model="changeState"
              :default-checked="state"
              :loading="loading"
              @change="onChange"
            />
          </div>
        </div>
        <!-- 返回上一级 -->
        <a-icon
          type="close" 
          :style="{ fontSize: '20px', color: '#7682CE' }"
          @click="goBack"
        />
      </div>
      <div class="main">
        <div class="main-top">        
          <div class="main-circle">          
              <div class="main-circle-title">
              </div>
              <div class="main-circle-value">
                {{tempValue}}
              </div>
              <div class="main-circle-status">
                {{switchDesc}}
              </div>
          </div>                   
        </div>        
        <div class="main-tab">
          <a-tabs default-active-key="1" @change="callback">
            <a-tab-pane v-for="(item, index) in digitalDatas" :key="index" :tab="item.signalName">
              <my-title v-if="item.pointType!=4" :title="title0"></my-title> 
              <my-statusInfo v-if="item.pointType!=4" :optInfo="item.statusInfo"  @transferData="getTransferData"></my-statusInfo>
              <my-title  v-if="item.pointType==4" :title="title4"></my-title> 
              <my-alertInfo v-if="item.pointType==4" :optInfo="item.alertInfo"  @transferData="getTransferData"></my-alertInfo>
              <my-title :title="title2"></my-title>
              <my-table :commentIds="item.comment" @transferData="getCommentData"></my-table>
              <div class="main-schedule">
                <my-scheduleCtrl :changeState="item.scheduleCtrl==1"></my-scheduleCtrl>
              </div>
              <my-title :title="title3"></my-title> 
              <my-tableMS v-if="item.pointType!=4" :monitor="item.monitor"  @transferData="getTransferMonitor"></my-tableMS>
              <my-tableMA v-if="item.pointType==4" :monitor="item.monitor"  @transferData="getTransferMonitor"></my-tableMA>
              <my-tableB :remark="item.remark"></my-tableB>
            </a-tab-pane>
            <a-tab-pane v-for="(item, index) in analogDatas" :key="index+digitalDatas.length" :tab="item.signalName"> 
              <my-title :title="title1"></my-title>      
              <my-tempStautsInfo :optInfo="item.measureInfo"  @transferData="getTransferData"></my-tempStautsInfo>
              <my-title :title="title5"></my-title>
              <my-upperAndLower :deviceType="deviceType" :monitor="item.monitor" @transferData="getTransferMonitor"></my-upperAndLower>
              <my-title :title="title2"></my-title>
              <my-table :commentIds="item.comment" @transferData="getCommentData"></my-table>              
              <my-tableB :remark="item.remark"></my-tableB>
            </a-tab-pane>
            <a-tab-pane v-for="(item, index) in multiDatas" :key="index+analogDatas.length+digitalDatas.length" :tab="item.signalName">
              <my-title :title="title0"></my-title>      
              <my-alertCodeInfo :optInfo="item.measureInfo"  @transferData="getTransferData"></my-alertCodeInfo>              
              <my-title :title="title2"></my-title>
              <my-table :commentIds="item.comment" @transferData="getCommentData"></my-table>              
              <my-tableB :remark="item.remark"></my-tableB>
            </a-tab-pane>           
          </a-tabs>
        </div>           
      </div>
      <div class="footer">
        <a-button type="primary" @click="onSetting">{{this.$t('lightDetails.d13')}}</a-button>
      </div>
    </div>
</template>

<script>
import tabletitle from "../../components/Title/tabletitle";
import lightTable from "../lightDetails/components/lightTableC";
import lightTableMS from "../lightDetails/components/lightTableMS";
import lightTableMA from "../lightDetails/components/lightTableMA";
import lightTableB from "../lightDetails/components/lightTableB";
import alertCodeInfo from "./components/alertCodeInfo";
import tempStautsInfo from "../air-conditionDetails/components/tempStautsInfo";
import statusInfo from "../lightDetails/components/statusInfo";
import alertInfo from "../lightDetails/components/alertInfo";
import upperAndLower from "../common/detail/upperAndLower";

import scheduleCtrl from "../lightDetails/components/scheduleCtrl";
import { getAirConditionWaterDetails, updateAnalogInputObj,updateAnalogOutputObj,updateBinaryData,updateMultipleData } from "../../api/device";
import substract_disable_img from '../../../public/images/air/air36.png'


export default {
  data() {
    return {
      spinning: false,
      loading: false,
      changeState: false,
      scheduleCtrl: false,
      title0: "状态",    
      title1: "测量值",  
      title2: "评论登记栏",
      title3: "维护监视",
      title4: "故障.警报",
      title5: "上下限值监视",
      selectKey:0,
      switchDesc:this.$route.query.switchDesc, 
      tempValue:this.$route.query.tempValue,      
      deviceName:this.$route.query.deviceName, 
      deviceType:this.$route.query.deviceType,
      comment:["","","","",""],    
      remark:{},
      monitor:{},  
      operationInfo:{},    
      data:{},
      analogDatas:[],
      digitalDatas:[],
      multiDatas:[],
      pointType:0,
      signalId:0,
    };
  },
  computed: {
    switchStatus: function () {
      if (this.$route.query.pv == 1) {
        return "ON";
      } else {
        return "OFF";
      }
    },
    state: function(){
      if(this.$route.query.pv==1){
        return true;
      }else{
        return false;
      }
    },
  },
  created() {
    this.getData();
  },
  methods: {
    getCommentData(msg){
      console.log("子组件传参内容 comment:",msg);
      this.comment = msg;
    },
    getTransferMonitor(msg){
      console.log("子组件传参内容 monitor:",msg);
      this.monitor = msg;
    },
    getTransferData(msg){
      console.log("子组件传参内容 info:",msg);
      this.operationInfo = msg;
    },
    //设置
    onSetting(){
      console.log("onSetting begin:"+this.selectKey);
      if(this.selectKey<this.digitalDatas.length){//数字信号信息
        this.updateBinaryData();
      }
      else if(this.selectKey<this.digitalDatas.length+this.analogDatas.length){//模拟信号信息
        this.StatusSetting();
      }
      else{
        this.updateMultipleData();
      }      
    },
    updateMultipleData(){
      this.loading = true;  
      let par = {
        signalId:this.signalId,
        pointType:this.pointType,
        measureInfo:this.operationInfo,
        comment:this.comment,
      }; 
      console.log("updateMultipleData par:", par);     
      updateMultipleData(par)
        .then((res) => {
          this.loading = false;
          let { data } = res;
          console.log("updateMultipleData result", data);
          this.$message.info(this.$t('lightDetails.d14')); 
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
          this.$message.error(this.$t('lightDetails.d15')); 
        });
    },
    updateBinaryData(){      
      this.loading = true;  
      let par = {
        signalId:this.signalId,
        pointType:this.pointType,
        operationInfo:this.operationInfo,
        statusInfo:this.operationInfo,
        alertInfo:this.operationInfo,
        comment:this.comment,
        monitor:this.monitor,
      }; 
      console.log("updateBinaryData par:", par);     
      updateBinaryData(par)
        .then((res) => {
          this.loading = false;
          let { data } = res;
          console.log("updateBinaryData result", data);
          if(data!=null &&data)
            this.$message.info(this.$t('lightDetails.d14')); 
          else
            this.$message.error(this.$t('lightDetails.d15')); 
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
          this.$message.error(this.$t('lightDetails.d15')); 
        }); 
    },
    StatusSetting(){      
      this.loading = true;  
      let par = {
        signalId:this.signalId,
        measureInfo:this.operationInfo,
        monitor:this.monitor,
        comment:this.comment,
      }; 
      console.log("updateAnalogInputObj par:", par);     
      updateAnalogInputObj(par)
        .then((res) => {
          this.loading = false;
          let { data } = res;
          console.log("updateAnalogInputObj result", data);
          if(data!=null &&data)
            this.$message.info(this.$t('lightDetails.d14')); 
          else
            this.$message.error(this.$t('lightDetails.d15')); 
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
          this.$message.error(this.$t('lightDetails.d15')+err); 
        }); 
    },
    callback(key) {
      this.refreshData(key);      
    },
    // 页面后退
    goBack() {
      this.$router.go(-1);
    },
    // 页面开关操作方法
    onChange(checked) {
    },
    getData() {  
      this.spinning = true;    
      let siteId = this.$route.query.siteId;
      let analogPoints = this.$route.query.analogPointIds;
      let digitalPoints = this.$route.query.digitalPointIds;
      let mutiPoints=this.$route.query.mutiPointIds;
      getAirConditionWaterDetails({siteId,analogPoints,digitalPoints,mutiPoints})
        .then((res) => {
          this.data = res.data;    
          console.log("airConditionWaterDetails:",this.data) 
          this.analogDatas = this.data.analogDatas;
          this.digitalDatas=this.data.digitalDatas;
          this.multiDatas=this.data.multiDatas;
              
          this.refreshData(0);    
          this.spinning = false;          
        })
        .catch((err) => {
          this.spinning = false;
          console.log(err)
        });
    },
    refreshData(key) {     
      this.selectKey=key;   
      var ikey = parseInt(key);     
      
      if(ikey<this.digitalDatas.length){//数字信号信息
        this.signalId = this.digitalDatas[ikey].signalId;
        this.pointType = this.digitalDatas[ikey].pointType;
        if(this.digitalDatas[ikey].comment==null){
          this.comment = ["","", "","", ""];
        }
        else{
          this.comment =this.digitalDatas[ikey].comment;
        }
        if(this.digitalDatas[ikey].pointType==4){//异常状态
          this.operationInfo = this.digitalDatas[ikey].alertInfo; 
          console.log("异常状态:",this.operationInfo);       
        }
        else{
          this.operationInfo = this.digitalDatas[ikey].statusInfo;
          console.log("非异常状态:",this.operationInfo);
        }
        this.scheduleCtrl= this.digitalDatas[ikey].scheduleCtrl==1?true:false;          
        this.remark = this.digitalDatas[ikey].remark;      
        this.monitor = this.digitalDatas[ikey].monitor; 
      }
      else if(ikey<this.digitalDatas.length+this.analogDatas.length){//模拟信号信息
          this.signalId = this.analogDatas[ikey-this.digitalDatas.length].signalId;
          this.pointType = this.analogDatas[ikey-this.digitalDatas.length].pointType;
          if(this.analogDatas[ikey-this.digitalDatas.length].comment==null){
            this.comment = ["","", "","", ""];
          }
          else{
            this.comment =this.analogDatas[ikey-this.digitalDatas.length].comment;
          }      
          this.operationInfo = this.analogDatas[ikey-this.digitalDatas.length].measureInfo;  
          this.remark = this.analogDatas[ikey-this.digitalDatas.length].remark;
          console.log("传参内容2:",this.remark);
      }
      else{//多状态信号信息
        this.signalId = this.multiDatas[ikey-this.digitalDatas.length-this.analogDatas.length].signalId;
        this.pointType = this.multiDatas[ikey-this.digitalDatas.length-this.analogDatas.length].pointType;
        if(this.multiDatas[ikey-this.digitalDatas.length-this.analogDatas.length].comment==null){
            this.comment = ["","", "","", ""];
          }
          else{
            this.comment =this.multiDatas[ikey-this.digitalDatas.length-this.analogDatas.length].comment;
          }        
          this.operationInfo = this.multiDatas[ikey-this.digitalDatas.length-this.analogDatas.length].measureInfo; 
          this.remark = this.multiDatas[ikey-this.digitalDatas.length-this.analogDatas.length].remark;
          console.log("传参内容3:",this.remark);
      }             
    }
  },
  components: {
    "my-title": tabletitle,
    "my-table": lightTable,
    "my-tableMS": lightTableMS,
    "my-tableMA": lightTableMA,    
    "my-tableB": lightTableB,
    "my-statusInfo": statusInfo,
    'my-alertInfo':alertInfo,
    "my-tempStautsInfo":tempStautsInfo,
    "my-scheduleCtrl": scheduleCtrl,
    "my-alertCodeInfo": alertCodeInfo,    
    "my-upperAndLower": upperAndLower,     
  },
};
</script>

<style scoped>
#LightDetails {
  width: calc(100% - 40px);
  box-sizing: border-box;
  box-shadow: 0px 0px 10px #bdbcbc;
  background: #ffffff;
  overflow: hidden;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  padding: 20px 20px 20px 20px;
  border-bottom: 1px solid #cccdcd;
  white-space: nowrap;
  overflow: hidden;
}
.header-header {
  display: flex;
  align-items: center;
}
.header-header span {
  margin-right: 30px;
}
.switch {
  display: flex;
  align-items: center;
}
.main {
  width: 100%;
  padding: 20px 20px 20px 20px;
  /* margin: 0 auto; */
}
.main-top {
  width:100%;
  /* margin: 0 auto; */
  float:left;
  padding-left:35%;
  
}
.main-tab{
  width:100%;
  float:left;
}
.image{  
  width:60px;
  margin:10px;
  font-size:13px;
  text-align:center;
  float:left;
}
.main-circle {
  padding:20px;
  width:180px;
  height:180px;
  border-radius: 50%;
  border:1px solid red; 
  display:inline-block;
  text-align:center;
  margin:20px;
  /* margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;
  margin-bottom: 20px; */
}
.main-circle-title {
  font-size:13px;
  color:#898989;
  margin-top:20%;
}
.main-circle-value {
  font-size:30px;
}
.main-circle-status {
  font-size:16px;
  color:red;
  margin-bottom:30%;
}
.main-slider{
  width:100%;
}
.slider-desc{
  padding-left:80px;
}
.slider{
  width:30%;
  float:left;
  /* padding-left:100px; */
}
.footer {
  height: 55px;
  width:100%;
  display: flex;
  justify-content: center;
  border-top: 1px solid rgba(12, 12, 12, 0.1);
  padding: 10px 20px 20px 20px;
}
</style>