<!--个别报警代码-->
<template>
  <div id="alertCodeInfo">
    <div class="alertCodeInfo-left">
      <div class="row">   
        <span>{{desc}}</span> 
        <span style="margin-right:16px;"> {{form.statusDesc}}</span>  
        <a-select :disabled="!form.maintenance" v-model="form.status" style="width: 80px" @change="dataChange">
          <a-select-option v-for="item in form.options" :key="item.value">
            {{item.desc}}
          </a-select-option>  
        </a-select>
        <span > {{$t('lightDetails.d1')}}</span> 
        <span style="margin-right:16px;"> {{form.changeDatetime}}</span> 
        <a-checkbox v-model= "form.maintenance" style="margin-right:16px;" @change="dataChange"> 
          {{$t('lightDetails.d3')}}
        </a-checkbox> 
      </div>      
    </div>    
  </div>
</template>

<script>
import {switchSettingMultiple} from '@/api/device';

export default {
  //父窗口传入参数
  props: {
    itemIndex: String,
    optInfo: Object,
    signalId: {
      type: Number,
      default: 0,
    },
  },
  //本地参数
  data() {
    return {   
      form: {
              changeDatetime: '',
              factor: '',
              status: '',
              statusDesc:'',
              maintenance: false,
              importantDevice: false,
              voiceType: '',
              options: [
                {value:"1",desc:"正常"},
                {value:"2",desc:"异常"},
              ],
              statusABs:[{value:"A接"},{value:"B接"}],
              statusAB:"A接",
      }, 
      desc:"状态：",
    };
  },   
  mounted(){
    this.setData(this.optInfo);
  },
  methods: {
    dataChange(e){ 
      console.log("dataChange:", this.form);      
      this.$emit('transferData',this.form);
    },
    setting(){
      //运转模式设置
      this.switchSettingMultiple();
    },    
    //运转模式设置
    switchSettingMultiple() {       
      this.loading = true;  
      console.log("dimmerSetting signalId:", this.signalId);
      let par = {
          signalId:this.signalId,//ID
          measureInfo: {status:this.form.status}//信息内容
      };
      console.log("dimmerSetting par:", par);     
      switchSettingMultiple(par)
        .then((res) => {
          this.loading = false;
          let { data } = res;
          console.log("dimmerSetting result", data);
          this.$message.info(this.$t('lightDetails.d14')); 
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
          this.$message.error(this.$t('lightDetails.d15')+err); 
        }); 
    },
    setData(val){
        if(val!=null){
          this.form.changeDatetime=val.changeDatetime;  
          this.form.factor=val.factor;        
          this.form.statusDesc=val.status==null?"":(val.status==1?"正常":"异常");
          this.form.status = val.status==null?"":val.status; 
          this.form.maintenance = val.maintenance;
          this.form.importantDevice=val.importantDevice; 
          this.form.voiceType=val.voiceType;     
          this.form.statusAB=val.statusAB;
        }
    },
  },
  watch:{
    optInfo: function (val){    
        // console.log("operateInfo data:", val);   
        this.setData(val);         
    },
  },  
};
</script>

<style scoped>
#alertCodeInfo {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /*内边距*/
  padding: 20px 20px 20px 20px; 
  
  border-radius: 3px;
  /* 阴影 */
  /* box-shadow: 0px 0px 10px #bdbcbc; */ 
  /* margin-bottom: 20px; */
  overflow: hidden;

  border-left: 1px solid #dedfdf;
  border-right: 1px solid #dedfdf;
  border-bottom: 1px solid #dedfdf;
  margin-bottom: 40px;
  white-space: nowrap;
  overflow: hidden;

}
.alertCodeInfo-left {
  display: flex;
}
p {
  margin-bottom: 0;
}
.row {
  margin-right: 40px;
}
.row span {
  font-size: 13px;
  color: #898989;
  white-space: nowrap;
}
.row p {
  font-size: 16px;
  white-space: nowrap;
}
.alertCodeInfo-right {
  display: flex;
  align-items: center;
}
button {
  width: 120px;
  margin-left: 15px;
}
</style>